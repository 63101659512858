/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/docs/en/main/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import * as Sentry from "@sentry/remix";

console.log(window.SENTRY_RELEASE);

Sentry.init({
  dsn: "https://ed518ea4bf234e6aa23a9a396797f0ad:57a4fbc4f9384c4c8329a11b2d448faa@o4505222722027520.ingest.sentry.io/4505222723076096",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches
      ),
    }),
    // Replay is only available in the client
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: window.SENTRY_RELEASE || 'dev',
});

startTransition(() => {
  const roout = hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
    {
      onRecoverableError(error, errorInfo) {
        console.error({ error, errorInfo });
        roout.unmount();

        createRoot(document as any).render(
          <StrictMode>
            <RemixBrowser />
          </StrictMode>);
      },
    }
  );
});
